import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useSocialSecurityContributions } from './useSocialSecurityContributions'
import { capitalize } from '@/utils/filter'

import useAPI from '@/utils/useAPI'
import SearchFilter from '@/components/searchFilter/SearchFilter'
import ModalSocialSecurityContributions from '@/components/prompt/SocialSecurityContributions'
import ModalUpload from '@/components/prompt/Upload'
import i18n from '@/libs/i18n'
import ButtonEdit from '@/components/button/Edit'
import ButtonDelete from '@/components/button/Delete'
import ButtonDownload from '@/components/button/Download'
import CardChargeTaxe from '../../../components/card/ChargeTaxe'

export const mixinSocialSecurityContributions = {
  components: {
    SearchFilter,
    ModalSocialSecurityContributions,
    ModalUpload,
    ButtonEdit,
    ButtonDownload,
    ButtonDelete,
    CardChargeTaxe,
  },
  props: {},
  setup () {
    const {
      submitValidatedSocialSecurityContribution,
      removeSocialSecurityContribution,
      recognizeSocialSecurityContributions,
      downloadSocialSecurityContribution
    } = useSocialSecurityContributions()

    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const additionalSortOptions = ref([])
    const tableColumns = ref([
      { key: 'supplier', label: i18n.tc('supplier', 1), sortable: true },
      { key: 'month', label: i18n.t('month'), sortable: true },
      { key: 'preTaxAmount', label: i18n.t('preTaxAmount'), sortable: true },
    ])
    const currentSocialSecurityContributions = ref([])
    const socialSecurityContributionModalTitle = ref(i18n.t('NewSocialSecurityContribution'))
    const socialSecurityContributionModalShow = ref(false)
    const newSocialSecurityContributionsFile = ref(null)
    const socialSecurityContributionsByUploadModalShow = ref(false)

    const selectedFilters = ref([])

    const newSocialSecurityContribution = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { socialSecurityContributions } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(socialSecurityContributionModalShow, (val) => {
      if (val == false) {
        resetSocialSecurityContribution()
      }
    })

    watch(newSocialSecurityContributionsFile, (val) => {
      if (val != null) {
        recognizeSocialSecurityContributions(val)
          .then(response => {
            socialSecurityContributionsByUploadModalShow.value = false
            currentSocialSecurityContributions.value = response.data
            newSocialSecurityContributionsFile.value = null
            socialSecurityContributionModalShow.value = true

            let i = 0
            currentSocialSecurityContributions.value.forEach(s => {
              s._uuid = i
              i++
            })

          })

      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchCompanies,
      fetchUsers,
      fetchPaymentMethods,
      fetchSocialSecurityContributions,
    } = useAPI()

    const resetSocialSecurityContribution = () => {
      currentSocialSecurityContributions.value = JSON.parse(JSON.stringify([]))
    }

    const addSocialSecurityContribution = () => {
      // console.log("addSocialSecurityContribution")
      socialSecurityContributionModalShow.value = true
    }

    const selectSocialSecurityContribution = (socialSecurityContributions) => {
      socialSecurityContributionModalTitle.value = i18n.t('EditSocialSecurityContribution')
      currentSocialSecurityContributions.value = [JSON.parse(JSON.stringify(socialSecurityContributions))]
      socialSecurityContributionModalShow.value = true
    }

    const submitValidatedSocialSecurityContributionLocal = (socialSecurityContributions) => {

      socialSecurityContributions.forEach(socialSecurityContribution => {
        submitValidatedSocialSecurityContribution(socialSecurityContribution).then(response => {
          socialSecurityContributionModalShow.value = false
          // console.log(response)
        })
      })

    }

    const removeSocialSecurityContributionLocal = (socialSecurityContribution) => {
      removeSocialSecurityContribution(socialSecurityContribution)
        .then(response => {
          // router.push({ name: 'SocialSecurityContributions' })
        })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchCompanies()
    fetchUsers()
    fetchPaymentMethods()
    fetchSocialSecurityContributions()

    return {
      // Components
      capitalize,

      // Data
      additionalSortOptions,
      tableColumns,
      currentSocialSecurityContributions,
      socialSecurityContributionModalTitle,
      socialSecurityContributionModalShow,
      newSocialSecurityContributionsFile,
      socialSecurityContributionsByUploadModalShow,

      newSocialSecurityContribution,

      // Computed
      socialSecurityContributions,

      // Methods
      addSocialSecurityContribution,
      selectSocialSecurityContribution,
      submitValidatedSocialSecurityContributionLocal,
      removeSocialSecurityContributionLocal,
      downloadSocialSecurityContribution,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteSocialSecurityContributionAlert (employee) {
      this.$bvModal
        .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theSocialSecurityContribution') }), {
          okVariant: 'danger',
          okTitle: this.capitalize(this.$t('delete')),
          cancelVariant: 'outline-secondary',
          cancelTitle: this.capitalize(this.$t('cancel')),
          centered: true,
        })
        .then(value => {
          if (value == true) {
            this.removeSocialSecurityContributionLocal(employee)
          }
        })
    }
  },
  mounted () {
  },
  created () {
  }
}