<template>
  <div>

    <div ref="formSocialSecurityContributions">

      <transition-group name="list-complete"
                        tag="p">

        <b-row
            v-for="(socialSecurityContribution, index) in socialSecurityContributions"
            :key="index"
            ref="row"
            class="list-complete-item pb-2"
        >
          <social-security-contribution
              :social-security-contribution="socialSecurityContribution"
              :index="index"
              :isEdition="isEdition"
              :allowRemove="allowRemove"
              @removeSocialSecurityContribution="removeSocialSecurityContributions(socialSecurityContribution._uuid)"
          />

        </b-row>

      </transition-group>

    </div>

    <button-add
        v-if="!isEdition"
        size="sm"
        @click="addNewSocialSecurityContribution"
        :text="$t('AddSocialSecurityContribution')"
    />

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'

import ButtonAdd from '@/components/button/Add'
import SocialSecurityContribution from './components/_socialSecurityContribution'

export default {
  components: {
    SocialSecurityContribution,
    ButtonAdd
  },
  props: {
    socialSecurityContributions: {
      type: Array,
      default: () => []
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    isEdition: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const allowRemove = computed(() => {
      if (props.allowEmpty) {
        return true
      } else {
        if (props.socialSecurityContributions.length > 1) {
          return true
        } else {
          return false
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const addNewSocialSecurityContribution = () => {
      props.socialSecurityContributions.push({
        month: (new Date()).toISOString().slice(0, 10),
      })
    }

    const removeSocialSecurityContributions = (uuid) => {
      let position = props.socialSecurityContributions.findIndex(s => s._uuid == uuid)
      props.socialSecurityContributions.splice(position, 1)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted(() => {
      if (!props.allowEmpty && props.socialSecurityContributions.length == 0) {
        props.socialSecurityContributions.push({
          month: (new Date()).toISOString().slice(0, 10),
        })
      }
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      allowRemove,

      // Methods
      addNewSocialSecurityContribution,
      removeSocialSecurityContributions,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss"
       scoped>
.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateY(30px);
}

.list-complete-leave-active {
  position: absolute;
}
</style>