import store from '@/store'

export const useSocialSecurityContributions = () => {

  const submitValidatedSocialSecurityContribution = (socialSecurityContribution) => {
    return new Promise((resolve, reject) => {
      if ('id' in socialSecurityContribution && socialSecurityContribution.id != null) {
        updateSocialSecurityContribution(socialSecurityContribution)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewSocialSecurityContribution(socialSecurityContribution)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewSocialSecurityContribution = (socialSecurityContribution) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution/addSocialSecurityContribution', socialSecurityContribution)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateSocialSecurityContribution = (socialSecurityContribution) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution/updateSocialSecurityContribution', socialSecurityContribution)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeSocialSecurityContribution = (socialSecurityContribution) => {
    return new Promise((resolve, reject) => {
      store.dispatch('socialSecurityContribution/removeSocialSecurityContribution', socialSecurityContribution)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const recognizeSocialSecurityContributions = (socialSecurityContributionsDocument) => {
    return new Promise((resolve, reject) => {
      store.dispatch('document/recognizeDocument', [socialSecurityContributionsDocument, 'socialSecurityContribution'])
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadSocialSecurityContribution = (socialSecurityContributionsDocument) => {
    let link = document.createElement('a')
    link.href = socialSecurityContributionsDocument.document.url
    link.target = '_blank'
    link.download = socialSecurityContributionsDocument.document.name + socialSecurityContributionsDocument.document.extension
    link.click()
  }

  // const getSocialSecurityContributionIconStatus = (socialSecurityContribution) => {
  //   if (getSocialSecurityContributionStatus(socialSecurityContribution) == 'draft') return { variant: 'secondary', icon: 'pencil-ruler' }
  //   else if (getSocialSecurityContributionStatus(socialSecurityContribution) == 'invoiced') return { variant: 'success', icon: 'file-invoice-dollar' }
  //   else return { variant: 'primary', icon: 'paper-plane' }
  // }

  return {
    submitValidatedSocialSecurityContribution,
    removeSocialSecurityContribution,
    recognizeSocialSecurityContributions,
    downloadSocialSecurityContribution,
    // getSocialSecurityContributionIconStatus
  }
}